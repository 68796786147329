import { mapState } from 'vuex'

export default mapState({
    trip: state => state.trip,
    paymentMethod: state => state.paymentMethod,
    totalValue: function(state) {
        return (
            state.cart.optionals.map(optional => optional.price * optional.quantity).reduce((partial, a) => partial + a, 0) +
            state.cart.accommodations.map(accommodation => accommodation.price * accommodation.quantity).reduce((partial, a) => partial + a, 0)
        )
    },
    user: state => state.session.user
})
