export default function() {
    if (window.dataLayer) {
        window.dataLayer.push({event: 'pageview', cart: this.$store.state.cart, page: 'orderComplete'});
    }

    // Disable browser back button
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
        history.go(1);
    };

    this.$store.commit('stopLoading')
}
