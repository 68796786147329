export default function () {
  return {
    paymentForm: '',
    creditCardNumber: '',
    creditCardName: '',
    creditCardExpDate: '',
    creditCardCode: '',
    invalidCard: false,
    error: false,
    invalidCardNumberMessage: '',
    invalidCardNameMessage: '',
    invalidCardExpDateMessage: '',
    invalidCardCvvMessage: '',
    billetUrl: '',
    billetExpireDate: '',
    checkoutId: null,
    availableInstallments: [],
    installments: {},
    cardDataValidated: false,
    showCheckoutError: false,
    acceptedTerms: [],
    pixTimerId: null,
    pixCodeCopied: false,
    pixData: {
      qrCode: null,
      code: null
    },
    validCardTypes: [
      'visa',
      'mastercard',
      'american-express',
      'elo',
      'hipercard'
    ],
    showSupportButton: false,
  }
}
