import * as Card from 'card'

export default function () {
  this.$store.commit('stopLoading')
  this.loadAvailableInstallments()

  this.createOrder()
    .then(() => {
      if (this.paymentMethod === 'credit_card' || !this.paymentMethod) {
        this.$store.commit('stopLoading')
        new Card({
          form: '#card-form',
          container: '#card-wrapper',
          formSelectors: {
            numberInput: '#creditCardNumber',
            expiryInput: 'input#creditCardExpDate',
            cvcInput: 'input#creditCardCode',
            nameInput: 'input#creditCardName'
          },
          placeholders: {
            number: '•••• •••• •••• ••••',
            name: 'Nome Completo',
            expiry: '••/••',
            cvc: '•••'
          },
          messages: {
            validDate: 'Validade',
            monthYear: 'mm/aa',
          }
        });
      }

      if (this.paymentMethod === 'pix') {
        this.createPixPayment()
      }
    })
    .catch(error => {
      console.log(error)
    })
}
