export default {
    creditCardNumber: function() {
        this.validatePaymentForm()
    },
    creditCardName: function() {
        this.validatePaymentForm()
    },
    creditCardExpDate: function() {
        this.validatePaymentForm()
    },
    creditCardCode: function() {
        this.validatePaymentForm()
    },
    installments: function() {
        this.validatePaymentForm()
        this.$store.commit('updateInstallments', this.installments)
    }
}
