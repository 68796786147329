import { mapState } from 'vuex'

export default mapState({
  totalValue: function(state) {
    let value = (
      state.cart.optionals.map(optional => optional.price * optional.quantity).reduce((partial, a) => partial + a, 0) +
      state.cart.accommodations.map(accommodation => accommodation.price * accommodation.quantity).reduce((partial, a) => partial + a, 0)
    )

    if (state.cart.coupon) {
      if (state.cart.coupon.type === 'percent') {
        value = value - ((value * state.cart.coupon.amount) / 100)
      }

      if (state.cart.coupon.type === 'fixed_amount') {
        value -= state.cart.coupon.amount
      }

      if (value < 0) {
        value = 0
      }
    }

    return value
  },
  cardDataFilled () {
    return !this.invalidCard && this.cardDataValidated
  },
  terms: function(state) {
    return state.acceptedTerms
  },
  orderData: function(state) {
    let accommodations = state.cart.accommodations
    accommodations.forEach(accommodation => {
      accommodation.optionTypeId = accommodation.id
      accommodation.title = accommodation.name
    })

    return {
      accommodations,
      acceptedAgencyTerms: this.terms?.agencyTerms?.id ? `/terms/${this.terms.agencyTerms.id}` : null,
      acceptedCompanyTerms: this.terms?.companyTerms?.id ? `/terms/${this.terms.companyTerms.id}` : null,
      cart: state.cart.id,
      optionals: state.cart.optionals,
      travelers: state.cart.travelers,
      paymentData: {
        installments: {
          code: state.installments.value,
          total: state.installments.total
        },
        type: state.paymentMethod
      }
    }
  },
  paymentMethod: function(state) {
    return state.paymentMethod
  },
  errors(state) {
    const result = []

    if (state.paymentMethod !== 'credit_card') {
      return result
    }

    if (!this.creditCardNumber) {
      result.push({
        hash: '#credit-card-header',
        path: 'cardNumber',
        message: 'Campo obrigatório'
      })
    }

    if (!this.creditCardName) {
      result.push({
        hash: '#credit-card-header',
        path: 'cardName',
        message: 'Campo obrigatório'
      })
    }

    if (!this.creditCardCode) {
      result.push({
        hash: '#credit-card-header',
        path: 'cardCode',
        message: 'Campo obrigatório'
      })
    }

    if (!this.creditCardExpDate) {
      result.push({
        hash: '#credit-card-header',
        path: 'cardExpDate',
        message: 'Campo obrigatório'
      })
    }

    return result
  },
  validationErrors() {
    return this.$store.state.errors
  }
})
