import {format} from "date-fns";

const cardValidator = require('card-validator')
import getApiClient from '@/plugins/api-client'
import axios from 'axios'

export default {
  pay() {
    this.showSupportButton = false
    this.$store.commit('startLoading')
    this.createCheckout()
      .then(id => {
        this.$store.commit('startLoading')

        axios.put(`https://api.sumup.com/v0.1/checkouts/${id}`, {
          payment_type: 'card',
          installments: this.installments.value,
          card: {
            number: this.creditCardNumber.replace(/[^0-9]/g, ''),
            name: this.creditCardName,
            expiry_month: this.creditCardExpDate.slice(0, 2),
            expiry_year: this.creditCardExpDate.slice(3, 5),
            cvv: this.creditCardCode,
          }
        })
          .then((result) => {
            if (result.data.status === 'PAID' && this.$store.state.order) {
              const api = getApiClient(this.$store)
              api.patch(`${this.$store.state.order['@id']}/complete`, this.orderData, {
                headers: {
                  'Content-Type': 'application/merge-patch+json'
                }
              })
                .then((result) => {
                  if (window.dataLayer) {
                    const taxes = (this.installments.total - this.totalValue).toFixed(2)
                    const dataLayerEvent = {
                      event: 'purchase',
                      currency: 'BRL',
                      transaction_id: result.data.id,
                      value: (this.installments.total - taxes).toFixed(2),
                      tax: taxes,
                      items: [
                        {
                          item_id: this.$store.state.trip.id,
                          item_name: this.$store.state.trip.eventTitle,
                          affiliation: this.$store.state.trip.agency.name
                        }
                      ]
                    }

                    window.dataLayer.push(dataLayerEvent)
                  }

                  this.$store.commit('setAcceptedTerms', {})
                  this.$router.push({name: 'Complete'})
                })
                .catch(error => {
                  console.log(error)
                })
            } else {
              this.$store.commit('stopLoading')
              this.invalidCardNumberMessage = 'Não foi possível realizar a cobrança com este cartão'
              this.showSupportButton = true
            }
          })
          .catch(error => {
            this.$store.commit('stopLoading')
            if (error.response.status === 400) {
              let data = error.response.data

              if (typeof data !== Array) {
                data = [data]
              }
              data.forEach(errorMessage => {
                switch (errorMessage.param) {
                  case '$.card.number':
                    this.invalidCardNumberMessage = 'Cartão inválido'
                    break;
                  case '$.card.name':
                    this.invalidCardNumberMessage = 'Nome inválido'
                    break;
                  case '$.card.expiry_year':
                  case '$.card.expiry_month':
                    this.invalidCardExpDateMessage = 'Cartão expirado'
                    break;
                  case '$.card.cvv':
                    this.invalidCardNumberMessage = 'CVV incorreto'
                    break;
                }
              })
            } else {
              this.showCheckoutError = true
            }
          })
      })
      .catch(() => {
        this.$store.commit('stopLoading')
      })
  },

  createPixPayment() {
    this.$store.commit('startLoading')
    this.createCheckout()
      .then(id => {
        this.$store.commit('startLoading')

        if (this.$store.state.pixData) {
          this.pixData = this.$store.state.pixData
          this.checkPixStatus()
          this.$store.commit('stopLoading')
          return
        }

        axios.put(`https://api.sumup.com/v0.1/checkouts/${id}`, {
          payment_type: 'pix',
        })
          .then((result) => {
            result.data.pix.artefacts.forEach(artefact => {
              if (artefact.name === 'barcode') {
                this.pixData.qrCode = artefact.location
              }

              if (artefact.name === 'code') {
                this.pixData.code = artefact.content
              }

              this.$store.commit('setPixData', this.pixData)

            })
            this.checkPixStatus()
            this.$store.commit('stopLoading')
          })
          .catch(error => {
            this.$store.commit('stopLoading')
            if (error.response.status === 409) {
              this.checkPixStatus()
              return
            }
            this.showCheckoutError = true
          })
      })
      .catch(() => {
        this.$store.commit('stopLoading')
      })
  },

  cancelCheckPixStatus() {
    if (this.pixTimerId) {
      window.clearInterval(this.pixTimerId)
    }
  },

  checkPixStatus() {
    const api = getApiClient(this.$store),
      cartId = this.$store?.state?.cart?.id

    this.cancelCheckPixStatus()

    if (!cartId) {
      return
    }
    this.pixTimerId = window.setInterval(() => {
      api.get(`${cartId}/checkout_status`)
        .then(result => {
          if (result.data.status === 'PAID' && this.$store.state.order) {
            this.cancelCheckPixStatus()

            api.patch(`${this.$store.state.order['@id']}/complete`, this.orderData, {
              headers: {
                'Content-Type': 'application/merge-patch+json'
              }
            })
              .then((result) => {
                if (window.dataLayer) {
                  const taxes = (this.installments.total - this.totalValue).toFixed(2)
                  const dataLayerEvent = {
                    event: 'purchase',
                    currency: 'BRL',
                    transaction_id: result.data.id,
                    value: (this.installments.total - taxes).toFixed(2),
                    tax: taxes,
                    items: this.getCartItems()
                  }

                  window.dataLayer.push(dataLayerEvent)
                }

                this.$store.commit('setAcceptedTerms', {})
                this.$router.push({name: 'Complete'})
              })
              .catch(error => {
                console.log(error)
              })
          }
        })
        .catch(() => {})
    }, 5000)
  },

  loadAvailableInstallments() {
    const self = this,
      api = getApiClient(self.$store)

    self.$store.commit('startLoading')

    api.get(`cart/calculate_installments?price=${self.totalValue}`)
      .then(result => {
        for (let installmentOption in result.data.installments) {
          const installment = result.data.installments[installmentOption],
            installmentAmount = new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            }).format(installment.installment_amount),
            totalAmount = new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            }).format(installment.amount),
            installmentObj = {
              label: `${installment.installment}x de ${installmentAmount}`,
              value: installment.installment,
              description: `Total de ${totalAmount}`,
              total: installment.amount
            }

          if (!self.installments.value) {
            self.installments = installmentObj
          }
          self.availableInstallments.push(installmentObj)
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        self.$store.commit('stopLoading')
      })
  },

  validatePaymentForm: function () {
    let isValid = true,
      self = this,
      requiredFields = [
        'creditCardNumber',
        'creditCardName',
        'creditCardExpDate',
        'creditCardCode',
        'installments'
      ]

    requiredFields.forEach(function (field) {
      if (!self[field]) {
        isValid = false
      }
    })

    let validateCard = cardValidator.number(self.creditCardNumber),
      validCardType = false

    if (validateCard.card) {
      const type = validateCard.card.type
      validCardType = self.validCardTypes.indexOf(type) > -1

      if (!validCardType && self.creditCardNumber.length > 3) {
        self.invalidCardNumberMessage = `Infelizmente, ainda não aceitamos cartões ${validateCard.card.niceType}`
      } else {
        self.invalidCardNumberMessage = null
      }
    }

    if (!self.invalidCardNumberMessage) {
      if (!validateCard.isPotentiallyValid && self.creditCardNumber.length > 15) {
        self.invalidCardNumberMessage = 'Cartão inválido'
      } else {
        self.invalidCardNumberMessage = null
      }
    }

    if (self.creditCardExpDate.length === 5) {
      let expDate = self.creditCardExpDate.split('/'),
        month = expDate[0],
        year = expDate[1],
        today = new Date(),
        todayMonth = today.getMonth() + 1,
        todayYear = today.getFullYear().toString().substr(-2)

      if (
        year < todayYear ||
        year === todayYear && month < todayMonth
      ) {
        self.invalidCardExpDateMessage = 'Cartão vencido'
      } else {
        self.invalidCardExpDateMessage = null
      }
    } else {
      self.invalidCardExpDateMessage = null
    }

    self.invalidCard = (!validateCard.isValid || !validCardType)

    if (self.invalidCard || self.invalidCardExpDateMessage) {
      isValid = false
    }

    if (self.invalidCard && self.creditCardNumber.length > 8) {
      self.invalidCardNumberMessage = 'Cartão inválido'
    }

    self.cardDataValidated = isValid

    return isValid
  },

  createCheckout() {
    const cart = this.$store.state.cart,
      api = getApiClient(this.$store)

    let total = 0

    if ((this.paymentMethod === 'credit_card' || !this.paymentMethod) && this.installments) {
      total = this.installments.total
    }

    if (this.paymentMethod === 'pix') {
      total = this.totalValue
    }

    if (this.installments) {
      return new Promise((resolve, reject) => {
        {
          api.patch(`${cart.id}/create_checkout`, {
            checkoutAmount: total.toFixed(2),
            installments: this.installments.value
          }, {
            headers: {
              'Content-Type': 'application/merge-patch+json'
            }
          })
            .then(result => {
              if (result.data.checkoutId) {
                this.checkoutId = result.data.checkoutId
                resolve(this.checkoutId)
              }
            })
            .catch(error => {
              reject(error)
            })
        }
      })
    }
  },

  getCartItems() {
    const result = []

    let boardingPlace = this.$store.state.trip.boardingPlaces.filter(place => {
      return place.id === this.$store.state.cart.boardingPlace
    })[0]

    if (boardingPlace) {
      const time = format(new Date(boardingPlace.date), 'dd/MM/yyyy HH:mm')
      boardingPlace = `Embarque: ${boardingPlace.place} - ${time}`
    }

    this.$store.state.cart.accommodations.forEach(accommodation => {
      result.push({
        item_id: this.$store.state.cart.id,
        item_name: accommodation.name,
        affilliation: this.$store.state.trip.agency.name,
        price: accommodation.value,
        quantity: accommodation.quantity,
        item_variant: boardingPlace
      })
    })

    this.$store.state.cart.optionals.forEach(optional => {
      result.push({
        item_id: this.$store.state.cart.id,
        item_name: optional.name,
        affilliation: this.$store.state.trip.agency.name,
        price: optional.value,
        quantity: optional.quantity
      })
    })

    return result
  },

  addToClipboard(value) {
    navigator.clipboard.writeText(value);

    this.pixCodeCopied = true
    window.setTimeout(() => {
      this.pixCodeCopied = false
    }, 2000)
  },

  createOrder() {
    return new Promise((resolve, reject) => {
      const api = getApiClient(this.$store)
      if (this.$store.state.order) {
        resolve(this.$store.state.order)
        return
      }
      api.post('orders', this.orderData)
        .then(result => {
          this.$store.commit('setOrder', result.data)
          resolve(result)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  requestPaymentSupport: function() {
    const amount = new Intl
        .NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'})
        .format(this.installments.total),
      message = encodeURIComponent(`Tentei realizar o pagamento do pedido ${this.$store.state.order.id} em ${this.installments.value} vezes, no valor total de ${amount}, poderia gerar um link para pagamento, no site meu cartão não está sendo aprovado.`),
      link = `https://api.whatsapp.com/send?phone=5511984269437&text=${message}`

    window.open(link)
  }
}
