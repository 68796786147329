import computed from './attrs/computed'
import mounted from './attrs/mounted'

import CartOverview from '../../components/CartOverview/Index'

export default {
    name: 'Complete',
    computed,
    mounted,
    components: {
        CartOverview
    }
}
